@import "@src/theme/variables";

.stages-chat-frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__header {
    padding: $space-lg;
    padding-bottom: $space-base;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 68px;
  }
}
