@import "@src/theme/variables";

.stages-invite-participant {
  &__wrapper {
    position: absolute;
    width: 375px;
    right: 0;
    bottom: calc(100% + $space-lg);
    transition: all $transition-time $transition-func;
    z-index: -1;

    &.entered,
    &.entering {
      opacity: 1;
      transform: translateY(0);
    }

    &.preEnter,
    &.exiting {
      // opacity: 0;
      transform: translateY(150%);
    }

    &.exited {
      // opacity: 0;
      transform: translateY(150%);
    }
  }

  &__content {
    border-radius: $rounded;
    background: $dark-blue-3;
    padding: $space-2xl;
    display: flex;
    flex-direction: column;
    gap: $space-2xl;
  }

  &__meeting-link {
    width: 100%;
    min-height: 48px;
    display: flex;
    padding: ($space-md - 2) $space-lg;
    align-items: center;
    justify-content: space-between;
    gap: $space-lg;
    border-radius: $rounded;
    background-color: $dark-blue-4;
    border: $bordered-xs-solid transparent;
    transition: border-color $transition-func 100ms;

    /* stylelint-disable  */
    & .ting-text.type_main {
      color: $light-slate;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--copied {
      border-color: $green-8;
      .ting-text.type_main {
        color: $white;
      }
    }
  }

  &__label {
    display: block;
    margin-bottom: $space-lg;
  }

  &__invite {
    display: flex;
    gap: $space-base;
  }

  &__meeting-info {
    display: flex;
    flex-direction: column;
    gap: $space-lg;
  }
  &__meeting-info-item {
    display: flex;
    justify-content: space-between;

    &:not(:last-of-type) {
      padding-bottom: $space-lg;
      border-bottom: $bordered-xs-solid $blue-zodiac;
    }
    .ting-text.type_sub {
      color: $dark-blue-8;
    }
    > :last-child {
      max-width: 210px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__meeting-info-action {
    display: flex;
    gap: $space-base;
    align-items: center;
    > :last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: $space-base;
  }
}
