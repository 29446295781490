@import "@src/theme/variables";

.stages-video-controller {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__section {
    display: flex;
    flex-direction: column;
    gap: $space-2xl;
  }

  &__camera-wrapper {
    display: flex;
    justify-content: space-between;
    gap: $space-lg;
  }

  &__video-frame {
    height: 80px;
    width: 132px;
  }
}
