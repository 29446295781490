@import "@src/theme/variables";

.stages-side-navigation {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-color: $dark-blue-0;
    left: 100%;
    right: calc(-1 * $space-lg);
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    left: $space-lg;
    background-color: $dark-blue-0;
  }

  &__tabs {
    &,
    .tabs {
      height: 100%;
    }

    .tabs {
      width: 56px;
      margin-left: $space-lg;
      background: $dark-three;
    }

    .tab {
      padding: 0 8px;
    }

    .tab-indicator {
      background-color: $dark-blue-4;
    }

    .tab--active,
    .tab-indicator--active {
      color: $white;
    }

    .tab__badge .ting-text {
      color: $black;
    }

    &__separator {
      margin-top: auto;
    }

    &--not-active .tabs {
      .tab-indicator {
        background-color: transparent;
      }
    }
  }
}
