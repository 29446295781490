@import "@src/theme/variables";

$transform-map: (
  1: translate(-16px, -14px),
  2: translate(-16px, 11px),
  3: translate(12px, -14px),
  4: translate(12px, 11px),
  5: translate(-2px, -20px),
  6: translate(-2px, 18px),
  7: translate(-18px, -1px),
  8: translate(14px, -1px),
  9: translate(22px, 5px),
  10: translate(22px, -7px),
  11: translate(-25px, 5px),
  12: translate(-25px, -7px),
);

.stages-effects-controller {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__camera-frame {
    height: 155px;
    width: 256px;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: $space-2xl;
  }

  &__blur-wrapper {
    display: flex;
    gap: $space-base;
    margin-top: $space-base;
  }

  &__blur-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 93px;
    height: 56px;
    border: 1px solid $blue-zodiac;
    border-radius: $rounded;
    background-color: $dark-blue-2;
    transition: border-color 0.2s;
    cursor: pointer;
    color: $light-slate;
    position: relative;

    &:hover {
      border-color: $dark-blue-8;
    }
  }

  &__blur-icon {
    position: absolute;
    color: $steel;

    svg {
      position: absolute;

      @each $index, $transform in $transform-map {
        &:nth-child(#{$index}) {
          transform: $transform;
        }
      }
    }
  }

  &__blur-item--active {
    border-color: $mango;
    outline: #593e35 solid 3px;
  }

  &__blur-item[aria-disabled="true"] {
    border-color: $blue-zodiac;
    outline: none;
    cursor: default;
  }

  &__blur-header {
    display: flex;
    align-items: center;
    gap: $space-base;

    > svg {
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}
