@import "@src/theme/variables";

.stages-chat {
  display: flex;
  flex-direction: column;
  width: 390px;
  margin-left: $space-lg;
  background: $dark-three;
  border: 1px solid $blue-zodiac;
  border-radius: $rounded;
  overflow: hidden;

  &__header {
    padding: $space-lg;
    padding-bottom: 0;
    border-bottom: 1px solid $blue-zodiac;
    background-color: $dark-blue-2;

    .tabs {
      margin-top: 8px;
      border: 0;
    }

    .tab__badge .ting-text {
      color: $black;
    }
  }

  &__indicator {
    position: static;
    margin-inline-start: $space-xs;

    &.badge .badge-content {
      position: static;
      background-color: $dark-blue-4;
      color: $dark-blue-8;
    }
  }

  &__content {
    flex-grow: 1;
    min-height: 0;
  }
}
