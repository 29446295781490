@import "@src/theme/variables";

.stages-poll-frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__footer {
    padding: $space-lg;
    border-top: 1px solid $blue-zodiac;
    background-color: $dark-blue-2;
    position: relative;
  }

  &__content {
    flex-grow: 1;
    min-height: 0;
  }
}
