@import "@src/theme/variables";

.stages-files-footer {
  &__add-file-btn {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }

  &__file-input {
    display: none;
  }

  &__add-file-dropdown {
    padding: 8px 12px;
    width: 216px;
    background-color: $dark-blue-3;
    border: 1px solid $dark-blue-6;
    border-radius: 8px;
    inset: unset !important;
    right: 16px !important;
    bottom: 16px !important;
    transform: translate(0, -58px) !important;
  }

  &__add-file-dropdown-items {
    li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }

      button {
        cursor: pointer;
        text-align: left;
        appearance: none;
        width: 100%;
        background-color: transparent;
        border: 0;
      }
    }
  }
}
