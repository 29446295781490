@import "@src/theme/variables";

.stages-select-participants {
  background: transparent;
  position: absolute;
  inset: 0 0 0 $space-lg;
  transition: background-color $transition-time $transition-func;
  border-radius: $space-base $space-base 0 0;

  &.entered,
  &.entering {
    background: rgb(0 0 0 / 30%);
  }

  &.exiting {
    background: transparent;
  }

  &.exited {
    background: transparent;
    opacity: 0;
    z-index: -99;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    background-color: $dark-blue-2;
    border-radius: $space-base $space-base 0 0;
    flex-grow: 1;
    margin-top: 32px;
    position: absolute;
    inset: 0;
    border: 1px solid $blue-zodiac;
    z-index: 999;
    transition: all $transition-time $transition-func;

    &.entered,
    &.entering {
      opacity: 1;
      transform: translateY(0);
    }

    &.exiting {
      opacity: 0;
      transform: translateY(100%);
    }

    &.exited {
      opacity: 0;
      transform: translateY(100%);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: $space-lg;
    border-bottom: $bordered-xs-solid $blue-zodiac;
  }

  &__content {
    padding: $space-2xl $space-lg;
  }

  &__peers {
    margin-top: $space-2xl;
  }

  &__profile-item {
    cursor: pointer;
  }
}
