@import "@src/theme/variables";

.stages-bottom-navigation {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $space-base;
  background: $dark-three;
  border-radius: $space-base;
}
