@import "@src/theme/variables";

.stages-live-chat-frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__header {
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__footer {
    padding: $space-lg;
    border-top: 1px solid $blue-zodiac;
    background-color: $dark-blue-2;
    position: relative;
  }

  &__message-options {
    display: flex;
    align-items: center;
    gap: 16px;
    color: $dark-blue-8;
    width: 100%;
    margin-bottom: 16px;
  }

  &__posting-as {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__posting-as-popover {
    padding: 8px 12px;
    width: 270px;
    background-color: $dark-blue-3;
    border-radius: 8px;
  }

  &__posting-as-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    background-color: transparent;
    width: 100%;

    svg {
      color: $white;
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }

  &__posting-as-heading {
    color: $dark-blue-8;
  }

  &__posting-as-btn {
    display: flex;
    align-items: center;
    padding: 0 8px;
    background-color: $dark-blue-4;
    border-radius: 8px;
  }

  &__messages-container {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    padding: 16px 0 0;
    display: flex;
    flex-direction: column;

    .popover-wrapper {
      padding: 0;
    }
  }

  &__messages-list {
    list-style: none;
    margin: 0;
    overflow: hidden auto;
    max-height: 100%;
    padding: 0;
    padding-top: 20px;
    flex-grow: 1;
    scroll-behavior: smooth;

    .live-chat-message {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  &__scroll-down-btn {
    position: absolute;
    left: calc(50% - 16px);
    bottom: 8px;
    border-radius: 50%;
    padding: 0;
    transition: bottom 150ms ease-in-out;

    &--hide {
      bottom: -32px;
    }
  }
}
