@import "@src/theme/variables";

.stages-files-frame {
  &__filters {
    display: flex;
    align-items: center;
    padding: 0 $space-lg $space-lg;
  }

  &__sort-selectbox {
    margin-left: $space-base;
    width: auto;
  }
}
