@import "@src/theme/variables";

.stages-audio-video-settings {
  .modalContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 776px;
    height: 720px;
  }

  &__container {
    display: grid;
    grid-template-columns: auto 1fr;
    overflow: hidden;
    flex-grow: 1;
    height: calc(100% - 1px);
  }

  &__content {
    padding: $space-2xl $space-3xl;
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
      background-color: $dark-blue-2;
    }

    &::-webkit-scrollbar-thumb {
      border-color: $dark-blue-2;
    }
  }

  &__side-tabs {
    gap: $space-base;
    padding: $space-base;
    border-right: 1px solid $blue-zodiac;

    .tabs {
      gap: 0;
      background-color: transparent;
    }

    .tab {
      width: 200px;
      height: 48px;
      justify-content: flex-start;
      gap: $space-base;
      color: $light-slate;
    }

    .tab--active {
      color: $white;
    }

    .tab-indicator {
      height: 48px;
      background-color: $dark-blue-4;
    }
  }
}
