@import "@src/theme/variables";

.stages-camera-frame {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: $rounded;
  overflow: hidden;
  box-sizing: border-box;

  &__video-element {
    height: calc(100% + 2px);
    left: -1px;
    pointer-events: none;
    position: absolute;
    top: -1px;
    width: calc(100% + 2px);
    transform: scaleX(-1);
    object-fit: cover;

    &--hide {
      visibility: hidden;
    }
  }

  &__video-off {
    background-color: $dark-blue-2;
    color: $blue-zodiac;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
