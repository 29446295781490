@use "sass:map";
@import "@src/theme/variables";
@import "@src/theme/breakpoints";

.stages-home-layout {
  display: flex;
  flex-direction: column;
}

.stages-lobby {
  background-color: $dark-three;
  flex-grow: 1;
  display: flex;

  &__small-screen-header {
    display: none;

    @include breakpoint-down(xl) {
      display: block;
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto $space-6xl;
    gap: $space-5xl;

    @include breakpoint-down(xl) {
      flex-direction: column;
      margin: $space-4xl;
      gap: $space-3xl;
    }
  }

  &__back-sec {
    background-color: transparent;
    border: 0;
    color: $white;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: $space-base;
    margin-bottom: $space-2xl;
  }

  &__stream-content {
    flex-grow: 1;
    max-width: 1040px;

    @include breakpoint-down(xl) {
      width: 480px;
      flex-grow: 0;
    }
  }

  &-info {
    width: 400px;

    &__big-screen-header {
      margin-bottom: $space-3-5xl;

      &--schedule {
        margin-bottom: $space-2xl;
      }
    }

    @include breakpoint-down(xl) {
      width: 480px;

      &__big-screen-header {
        display: none;
      }
    }

    &__input {
      margin-bottom: $space-lg;
    }

    &__submit {
      margin-top: $space-2xl;
      width: 100%;
    }
  }
}
