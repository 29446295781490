@import "@src/theme/variables";

.stages-join-room {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    min-width: 480px;
  }

  &__input {
    margin-top: $space-3-5xl;
  }

  &__submit {
    margin-top: $space-3-5xl;
    width: 100%;
  }
}
