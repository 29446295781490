@import "@src/theme/variables";

.stages-peer-list {
  margin: 0 $space-base;
  /* stylelint-disable  */
  &__subtitle.ting-text.type_main {
    margin-top: $space-lg;
    margin-inline-start: $space-base;
    color: $dark-blue-8;
  }

  &__raise-hand-subtitle {
    display: flex;
    align-items: center;
    gap: $space-base;
    margin-top: $space-lg;
    margin-inline-start: $space-base;

    .ting-text.type_main {
      color: $dark-blue-8;
    }

    > svg {
      color: $orange-10;
    }
  }

  &__peers {
    margin-top: $space-base;
  }

  &__title {
    margin: $space-xl $space-base 0;
  }

  &__content {
    margin-top: $space-xs;
  }

  &__search {
    margin: $space-xl $space-base 0;
    width: calc(100% - $space-base * 2);
  }
}
