@import "@src/theme/variables";

.stages-live-chat {
  display: flex;
  flex-direction: column;
  width: 390px;
  margin-left: $space-lg;
  background: $dark-three;
  border: 1px solid $blue-zodiac;
  border-radius: $rounded;
  overflow: hidden;
  position: relative;

  &__header {
    padding: $space-lg;
    padding-bottom: 0;
    border-bottom: 1px solid $blue-zodiac;
    background-color: $dark-blue-2;

    .tabs {
      margin-top: 8px;
      border: 0;
    }
  }

  &__content {
    flex-grow: 1;
    min-height: 0;
  }

  &__not-active-state {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    min-height: 0;
    justify-content: center;
    align-items: center;

    > .ting-text:last-child {
      color: $dark-blue-8;
    }
  }

  &__chat-icon {
    display: flex;
    border-radius: 50%;
    padding: 10px;
    background-color: $dark-blue-3;
  }
}
