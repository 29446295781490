@import "@src/theme/variables";

.stages-main {
  display: flex;
  flex-flow: row nowrap;
  width: 100vw;
  height: 100vh;
  position: relative;

  --side-navbar-size: 88px;
  --gap-size: #{$space-lg};
  --chat-box-size: 388px;

  > * {
    display: flex;
  }

  &__view-container {
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    inset: var(--gap-size);
    right: calc(var(--chat-box-size) + var(--side-navbar-size));
    transition: right $transition-time $transition-func;

    &.preEnter,
    &.exiting {
      right: var(--side-navbar-size);
    }

    &.exited {
      right: var(--side-navbar-size);
    }
  }

  &__chat-container {
    position: absolute;
    inset-block: var(--gap-size);
    right: var(--side-navbar-size);
    transition: all $transition-time $transition-func;
    width: 390px;

    &.entered,
    &.entering {
      opacity: 1;
      transform: translateX(0);
    }

    &.preEnter,
    &.exiting {
      opacity: 0;
      transform: translateX(100%);
    }

    &.exited {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  &__side-navbar {
    position: absolute;
    inset-block: var(--gap-size);
    right: var(--gap-size);
  }

  &__view {
    flex-grow: 1;
    max-height: calc(100% - (56px + $space-lg));
  }

  &__control {
    margin-top: $space-lg;
    position: relative;
    z-index: 10;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      left: $space-lg;
      background-color: $dark-blue-0;
      z-index: -1;
    }
  }
}
