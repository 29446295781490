@import "@src/theme/variables";

.stages-profile-row {
  display: flex;
  align-items: center;
  gap: $space-lg;
  padding: $space-base;
  transition: background-color 200ms ease;
  border-radius: 8px;

  &:hover {
    background-color: $dark-blue-3;
  }

  &__content {
    flex: 1;
    min-width: 0;
  }

  &__name-container {
    display: flex;
    align-items: center;
    gap: $space-xs;
  }

  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__actions {
    display: flex;
    gap: $space-base;
  }
  /* stylelint-disable  */
  &__caption.ting-text.type_main {
    color: $steel;
  }
}
