@import "@src/theme/variables";

.stages-audio-controller {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__section {
    display: flex;
    flex-direction: column;
    gap: $space-lg;
  }

  &__section > :first-child {
    margin-bottom: $space-base;
  }

  &__mic-wrapper {
    display: flex;
    align-items: flex-end;
    gap: $space-lg;
  }

  &__mic-off-wrapper {
    display: flex;
    min-width: 128px;
    gap: $space-base;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-radius: $rounded;
    border: 1px solid $blue-zodiac;
    background-color: $dark-blue-3;
    color: $steel;
    /* stylelint-disable  */
    & .ting-text.type_main {
      color: $steel;
    }
  }
}
